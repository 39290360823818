import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import Host from '../components/Host'
import Header from '../components/Header'
import CreditCard from '../components/CreditCard'
import Drivers from '../components/Drivers'
import DrivingLicense from '../components/DrivingLicense'
import Payout from '../components/Payout'
import Tax from '../components/Tax'
import User from '../components/User'

import firebase from "firebase/app";
import 'firebase/auth';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '35%'
  },
  nav: {
    fontSize: '1.2rem',
    color: '#008F8C',
    fontWeight: 'bold',
    cursor: 'pointer'
  }
});

function Home() {
  const classes = useStyles();

  const [selected, setSelected] = useState(1);
  const [navigator, setNavigator] = useState(false);
  const [showBankInfo, setShowBankInfo] = useState(false);
  const [bankInfo, setBankInfo] = useState({});
  const [showPayout, setShowPayout] = useState(false);
  const [showTax, setShowTax] = useState(false);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    window
      .matchMedia("(max-width: 600px)")
      .addEventListener('change', e => setNavigator(false));
  }, [])

  const logout = () => {
    setSelected(5);

    firebase
      .auth()
      .signOut()
      .then(() => {
        setNavigator(false)
      }).catch(err => {
        alert(err)
      });
  }

  return (
    <>
      <Header
        selected={selected}
        setSelected={setSelected}
        navigator={navigator}
        setNavigator={setNavigator}
        showBankInfo={showBankInfo}
        setShowBankInfo={setShowBankInfo}
        bankInfo={bankInfo}
        setShowPayout={setShowPayout}
        showPayout={showPayout}
        showTax={showTax}
        setShowTax={setShowTax}
        update={update}
        setUpdate={setUpdate}
        logout={logout}
      />
      {navigator ?
        <div className={classes.container}>
          <p onClick={() => { setNavigator(false); setSelected(1) }} className={classes.nav}>
            Driver
          </p>
          <p onClick={() => { setNavigator(false); setSelected(2) }} className={classes.nav}>
            User
          </p>
          <p onClick={() => { setNavigator(false); setSelected(3) }} className={classes.nav}>
            Credit Card
          </p>
          <p onClick={() => { setNavigator(false); setSelected(4) }} className={classes.nav}>
            Driver License
          </p>
          <p onClick={() => { setNavigator(false); setSelected(5) }} className={classes.nav}>
            Tax
          </p>
          <p onClick={() => { setNavigator(false); setSelected(6) }} className={classes.nav}>
            Payout
          </p>
          <p onClick={logout} className={classes.nav}>
            log out
          </p>
        </div>
        : (selected === 1 ?
          <Drivers />
          : selected === 2 ?
            <User />
            : selected === 3 ?
              <CreditCard />
              : selected === 4 ?
                <DrivingLicense />
                : selected === 5 ?
                  <Tax showTax={showTax} setShowTax={setShowTax} />
                  : selected === 6 ?
                    <Payout setShowBankInfo={setShowBankInfo} setBankInfo={setBankInfo} setShowPayout={setShowPayout} showPayout={showPayout} update={update} setUpdate={setUpdate} />
                    :
                    <></>)
      }
    </>
  )
}

export default Home