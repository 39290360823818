import { Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import BankInfo from './BankInfo';
import UpdatePayout from './UpdatePayout';
import AddTax from './AddTax';

const useStyles = makeStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      backgroundColor: 'white',
      justifyContent: 'space-between',
      boxShadow: '1px 2px 9px #00cdac',
      marginBottom: '3rem',
      position: 'fixed',
      top: 0,
      zIndex: 100,
    },
    tabsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        "@media (max-width: 600px)": {
            width: '75%',
            marginTop: 8,
        },
        "@media (min-width: 600px)": {
            width: '75%',
            marginTop: 8,
        },
        "@media (min-width: 768px)": {
            width: '60%',
            marginTop: 8,
        },
        "@media (min-width: 992px)": {
            width: '50%',
            marginTop: 8,
        },
        "@media (min-width: 1200px)": {
            width: '40%',
            marginTop: 8,
        },
    },
    tabs: {
        color: 'grey',
        textDecoration: 'none',
    },
    activeTabs: {
        color: '#00cdac',
        textDecoration: 'none',
    }
});  

function Header({ selected, setSelected, navigator, setNavigator, showBankInfo, setShowBankInfo, bankInfo, setShowPayout, showPayout, showTax, setShowTax, update, setUpdate, logout }) {
    const classes = useStyles();

    const [matches, setMatches] = useState(
        window
            .matchMedia("(max-width: 600px)").matches
    );

    useEffect(() => {
        window
            .matchMedia("(max-width: 600px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);

    return (
        <div className={classes.container}>
            <Avatar
                alt='logo'
                src={require('../images/logo.png')}
                sx={{ 
                    width: 50, 
                    height: 50, 
                    borderRadius: 25,
                    margin: 2,
                    "@media (max-width: 600px)": {
                        marginLeft: 1.75
                    },
                    "@media (min-width: 992px)": {
                        marginLeft: 5,
                    },
                }}
            />
            { matches ?
                !navigator ?
                    <MenuIcon onClick={() => setNavigator(!navigator)} fontSize='large' color='primary' sx={{ marginRight: '4%', marginTop: '1.25rem' }} />
                :
                    <CloseIcon onClick={() => setNavigator(!navigator)} fontSize='large' color='primary' sx={{ marginRight: '4%', marginTop: '1.25rem' }} />
            :
                <div className={classes.tabsContainer}>
                    <h4>
                        <a href='#' onClick={() => setSelected(1)} className={selected === 1 ? classes.activeTabs : classes.tabs}>
                            Driver
                        </a>
                    </h4>
                    <h4>
                        <a href='#' onClick={() => setSelected(2)} className={selected === 2 ? classes.activeTabs : classes.tabs}>
                            User
                        </a>
                    </h4>
                    <h4>
                        <a href='#' onClick={() => setSelected(3)} className={selected === 3 ? classes.activeTabs : classes.tabs}>
                            Credit Card
                        </a>
                    </h4>
                    <h4>
                        <a href='#' onClick={() => setSelected(4)} className={selected === 4 ? classes.activeTabs : classes.tabs}>
                            Driver License
                        </a>
                    </h4>
                    <h4>
                        <a href='#' onClick={() => setSelected(5)} className={selected === 5 ? classes.activeTabs : classes.tabs}>
                            Tax
                        </a>
                    </h4>
                    <h4>
                        <a href='#' onClick={() => setSelected(6)} className={selected === 6 ? classes.activeTabs : classes.tabs}>
                            Pay out
                        </a>
                    </h4>
                    <h4>
                        <a href='#' onClick={logout} className={selected === 7 ? classes.activeTabs : classes.tabs}>
                            Log out
                        </a>
                    </h4>
                </div>
            }
            { showBankInfo && 
                <BankInfo setShowBankInfo={setShowBankInfo} bankInfo={bankInfo} />
            }
            { showPayout &&
                <UpdatePayout setShowPayout={setShowPayout} bankInfo={bankInfo} update={update} setUpdate={setUpdate} />
            }
            { showTax &&
                <AddTax setShowTax={setShowTax} />
            }
        </div>
    )
}

export default Header;