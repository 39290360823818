import React, { useState, useEffect } from 'react';
import { Avatar, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
import DataTable from 'datatables.net-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-buttons-dt';
import 'datatables.net-select-dt';

import firebase from "firebase/app";
import 'firebase/firestore';

import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net-responsive-dt/css/responsive.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import 'datatables.net-select-dt/css/select.dataTables.css';

const useStyles = makeStyles({
    container: {
        margin: '5px',
        marginTop: '100px',
    },
    titleItem: {
        display: 'flex',
        "@media (max-width: 992px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%'
        },
        "@media (min-width: 992px)": {
            flexDirection: 'row',
            width: '95%',
        },
        backgroundColor: '#fff',
        boxShadow: '1px 2px 6px #000',
        marginTop: '1.5rem',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    item: {
        display: 'flex',
        "@media (max-width: 992px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%'
        },
        "@media (min-width: 992px)": {
            flexDirection: 'row',
            width: '95%',
        },
        backgroundColor: '#fff',
        boxShadow: '1px 2px 6px #000',
        marginTop: '1.5rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        alignItems: 'center',
    },
    noRequestFound: {
        marginTop: '10px',
        textAlign: 'center',
    },
    emailValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (max-width: 992px)": {
            marginTop: '2rem'
        },
        "@media (min-width: 992px)": {
            width: '23.75rem',
        },
        textAlign: 'center',
    },
    titleValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (max-width: 992px)": {
            marginTop: '1rem'
        },
        "@media (min-width: 992px)": {
            width: '23.75rem',
        },
        textAlign: 'center',
    },
    username: {
        fontWeight: 'bold',
        color: 'blue',
        "@media (max-width: 992px)": {
            marginTop: '2rem'
        },
        "@media (min-width: 992px)": {
            width: '23.75rem',
        },
        textAlign: 'center',
        textDecoration: 'none'
    },
    avatar: {
        "@media (max-width: 992px)": {
            marginTop: '1rem'
        },
        "@media (min-width: 992px)": {
            width: '10%',
        },
    },
    avatarTitle: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (min-width: 992px)": {
            width: '10%',
        },
        textAlign: 'center',
        marginLeft: 10
    },
    insuranceUrl: {
        fontWeight: 'bold',
        color: '#BD2A2E',
        "@media (max-width: 992px)": {
            marginTop: '1rem',
            marginBottom: '1rem'
        },
        "@media (min-width: 992px)": {
            width: '23.75rem',
        },
        textAlign: 'center',
        textDecoration: 'none'
    },
    insuranceUrlBtn: {
        fontWeight: 'bold',
        color: '#BD2A2E',
        "@media (max-width: 992px)": {
            marginTop: '.5rem',
            marginBottom: '1rem'
        },
        "@media (min-width: 992px)": {
            width: '23.75rem',
        },
        textAlign: 'center',
        textDecoration: 'none'
    },

});

function Drivers() {
    const classes = useStyles();

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const [filter, setFilter] = useState('');
    const [table, setTable] = useState(null);

    useEffect(() => {
        (async () => {
            setLoading(true);
            let transformArray = [];

            try {
                //const { users } = await firebase.auth().listUsers(100);

                const users = await firebase
                    .firestore()
                    .collection('users')
                    .get();

                if (users.size === 0) {
                    setLoading(false)
                    return;
                }

                for (const userDoc of users.docs) {
                    const userData = userDoc.data();

                    const { regionId } = userData;
                    let regionData = {};
                    if (regionId) {
                        const regionDoc = await firebase.firestore().collection('regions').doc(regionId).get();
                        const { city, province } = regionDoc.data();
                        regionData = {
                            city,
                            province,
                        }
                    }

                    const driverDoc = await firebase.firestore().collection('drivers').doc(userDoc.id).get();
                    const driverData = driverDoc.data();

                    transformArray.push({
                        id: userDoc.id,
                        ...userData,
                        ...driverData,
                        ...regionData,
                    });
                }

                setUsers(transformArray);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                console.error(err);
                alert(err);
            }
        })()

        return () => { };
    }, [update]);

    useEffect(() => {
        if (users && users.length > 0) {
            if (!table) {
                const _table = new DataTable('#drivers-list-table', {
                    responsive: true,
                    select: true,
                    dom: 'Bfrtip', // Define where buttons will be placed
                    buttons: [
                        {
                            text: 'Send Push Notification (Selected)',
                            action: function (e, dt, node, config) {
                                var selectedRowsData = _table.rows({ selected: true }).data();
                                processSelectedRows(selectedRowsData);
                            }
                        },
                        {
                            text: 'Send Push Notification (All)',
                            action: function (e, dt, node, config) {
                                var allRowsData = _table.rows().data();
                                processAllRows(allRowsData);
                            }
                        }
                    ]
                });
                setTable(_table);
            }
        }
    }, [users]);

    const processSelectedRows = (selectedRowsData) => {
        // Handle your logic for selected rows
        var driverIds = [];
        selectedRowsData.each(function (rowData) {
            var t = document.createRange().createContextualFragment(rowData[1]);
            var driverId = t.firstElementChild.getAttribute('href').replace('/driver/', '');
            driverIds.push(driverId);
        });
        console.log(driverIds.join('\n'));
    };

    const processAllRows = (allRowsData) => {
        // Handle your logic for all rows
        var driverIds = [];
        allRowsData.each(function (rowData) {
            var t = document.createRange().createContextualFragment(rowData[1]);
            var driverId = t.firstElementChild.getAttribute('href').replace('/driver/', '');
            driverIds.push(driverId);
        });
        console.log(driverIds.join('\n'));
    };

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const filterData = async (value) => {
        try {
            let transformArray = [];

            const userSnapshot = await firebase
                .firestore()
                .collection('users')
                .where('licenseStatus', '==', value)
                .get();

            if (userSnapshot.size === 0) {
                setUsers([]);
                setLoading(false);
                return;
            }

            for (const userDoc of userSnapshot.docs) {
                const userData = userDoc.data();

                try {
                    let driverDoc = await firebase
                        .firestore()
                        .collection('drivers')
                        .doc(userDoc.id)
                        .get();

                    const driverData = driverDoc.data();

                    transformArray.push({
                        id: userDoc.id,
                        ...userData,
                        ...driverData,
                    });
                } catch (err) {
                    console.error(err);
                }
            }

            console.log();
            setUsers(transformArray);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            alert(err);
        }
    }

    const verify = async (id) => {
        try {
            setLoading(true);

            await firebase
                .firestore()
                .collection('users')
                .doc(id)
                .update({
                    driverStatus: 'Allowed'
                });

            if (filter === '' || filter === 'All') {
                setUpdate(!update);
                return;
            }

            await filterData(filter);
        } catch (err) {
            setLoading(false);
            alert(err)
        }
    };

    const handleChange = async (event) => {
        setFilter(event.target.value);

        try {
            if (event.target.value === 'All') {
                setUpdate(!update);
                return
            }

            setLoading(true);

            await filterData(event.target.value);
        } catch (err) {
            setLoading(false);
            alert(err);
        }
    };

    return <div className={classes.container}>
        <table id='drivers-list-table' style={{ width: '100%' }}>
            <thead>
                <tr>
                    <td>
                        Avatar
                    </td>
                    <td>
                        Driver name
                    </td>
                    <td>
                        Email
                    </td>
                    <td>
                        City/State/Country
                    </td>
                    <td>
                        License
                    </td>
                    <td>
                        Status
                    </td>
                </tr>
            </thead>
            {loading ?
                <tbody>
                    <tr>
                        <td colSpan={6}>
                            <div className={classes.noRequestFound}>
                                <CircularProgress color="secondary" />
                            </div>
                        </td>
                    </tr>
                </tbody>
                : users.length === 0 ?
                    <tbody>
                        <tr>
                            <td colSpan={6}>
                                No User Found.
                            </td>
                        </tr>
                    </tbody>
                    : <tbody>
                        {
                            users.map((item, i) =>
                                <tr key={i} >
                                    <td>
                                        <Avatar
                                            alt='logo'
                                            src={item.photoURL}
                                            sx={{
                                                width: 45,
                                                height: 45,
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Link to={`/driver/${item.id}`} className={classes.username}>
                                            {item?.fname} {item?.lname}
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to={'mailto:' + item.email}>{item.email}</Link>
                                    </td>
                                    <td>
                                        {item?.city} {item?.province} {item?.country}
                                    </td>
                                    {item.licenseStatus === 'Not Provided'
                                        ? <td>
                                            {item.licenseStatus}
                                        </td>
                                        :
                                        <td>
                                            <a href="#" onClick={() => openInNewTab(item.licenseURL)} className={classes.insuranceUrl}>
                                                View
                                            </a>
                                        </td>
                                    }
                                    {item.hasOwnProperty('driverStatus') && item.driverStatus == 'Allowed'
                                        ? <td>
                                            {item.driverStatus}
                                        </td> : <td>
                                            Not Allowed
                                        </td>}
                                </tr>
                            )
                        }
                    </tbody>
            }
        </table>
    </div>;
}

export default Drivers;