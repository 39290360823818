const firebaseConfig = {
    apiKey: "AIzaSyAJ4JTSioEa-ooFOucfED3GLuujtko28Xs",
    authDomain: "ryde-c6d03.firebaseapp.com",
    databaseURL: "https://ryde-c6d03-default-rtdb.firebaseio.com",
    projectId: "ryde-c6d03",
    storageBucket: "ryde-c6d03.appspot.com",
    messagingSenderId: "473518790130",
    appId: "1:473518790130:web:b1ac441e82086e71fe476b",
    measurementId: "G-7667PY2W7L"
};

export default firebaseConfig;
