import React, { useState, useEffect } from 'react';
import { Avatar, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from "react-router-dom";
import DataTable from 'datatables.net-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-buttons-dt';
import 'datatables.net-select-dt';

import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/functions';

import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net-responsive-dt/css/responsive.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import 'datatables.net-select-dt/css/select.dataTables.css';

const useStyles = makeStyles({
    container: {
        margin: '5px',
        marginTop: '100px',
    },
    titleItem: {
        display: 'flex',
        "@media (max-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%'
        },
        "@media (min-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%',
        },
        "@media (min-width: 992px)": {
            flexDirection: 'row',
            width: '95%',
        },
        backgroundColor: '#fff',
        boxShadow: '1px 2px 6px #000',
        marginTop: '1.5rem',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    item: {
        display: 'flex',
        "@media (max-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%',
        },
        "@media (min-width: 600px)": {
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '90%'
        },
        "@media (min-width: 992px)": {
            flexDirection: 'row',
            width: '95%',
        },
        backgroundColor: '#fff',
        boxShadow: '1px 2px 6px #000',
        marginTop: '1.5rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        alignItems: 'center',
    },
    noRequestFound: {
        marginTop: '10px',
        textAlign: 'center',
    },
    emailValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (max-width: 600px)": {
            marginTop: '2rem',
        },
        "@media (min-width: 600px)": {
            marginTop: '2rem'
        },
        "@media (min-width: 992px)": {
            width: '26.6%',
            marginTop: '1.1rem'
        },
        textAlign: 'center',
    },
    lastActiveValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (min-width: 992px)": {
            width: '26.6%',
            marginTop: '1.1rem'
        },
        textAlign: 'center',
    },
    titleValue: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (min-width: 992px)": {
            width: '26.6%',
        },
        textAlign: 'center',
    },
    username: {
        fontWeight: 'bold',
        color: 'blue',
        "@media (max-width: 600px)": {
            marginTop: '2rem'
        },
        "@media (min-width: 600px)": {
            marginTop: '2rem'
        },
        "@media (min-width: 992px)": {
            width: '26.6%',
            marginTop: '0'
        },
        textAlign: 'center',
        textDecoration: 'none'
    },
    avatar: {
        fontWeight: 'bold',
        color: 'grey',
        "@media (min-width: 992px)": {
            width: '10%',
        },
        textAlign: 'center',

    },
    avatarValue: {
        "@media (max-width: 600px)": {
            marginTop: '1rem'
        },
        "@media (min-width: 600px)": {
            marginTop: '1rem'
        },
        "@media (min-width: 992px)": {
            width: '10%',
            marginTop: '0'
        },
    },
    accessBtn: {
        fontWeight: 'bold',
        color: '#042940',
        "@media (min-width: 992px)": {
            width: '21.6%',
        },
        textAlign: 'center',
        textDecoration: 'none'
    }
});

function User() {
    const classes = useStyles();

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const [filter, setFilter] = useState('');
    const [pageToken, setPageToken] = useState(null);
    const [table, setTable] = useState(null);

    useEffect(() => {
        setLoading(true);

        (async () => {
            try {
                let result;

                const getInactiveUsers = firebase.functions().httpsCallable('getInactiveUsers');

                if (pageToken) {
                    result = await getInactiveUsers({
                        nextPageToken: pageToken
                    });
                } else {
                    result = await getInactiveUsers({});
                }

                const { data } = result;

                setUsers(data.users);

                setPageToken(data.pageToken);

                setLoading(false);
            } catch (err) {
                setLoading(false);
                alert(err);
            }
        })()
    }, [update]);

    useEffect(() => {
        if (users && users.length > 0) {
            if (!table) {
                const _table = new DataTable('#users-list-table', {
                    responsive: true,
                    select: true,
                    order: [[3, 'desc']],
                });
                setTable(_table);
            }
        }
    }, [users]);

    const filterData = async (value) => {
        try {
            let result;
            let inactiveUsers = [];

            const getInactiveUsers = firebase.functions().httpsCallable('getInactiveUsers');

            if (pageToken) {
                result = await getInactiveUsers({
                    nextPageToken: pageToken
                });
            } else {
                result = await getInactiveUsers({});
            }

            const { data } = result;

            if (value === '30 days') {
                inactiveUsers = data.users.filter(
                    user => Date.parse(user.metadata.lastRefreshTime || user.metadata.lastSignInTime) < (Date.now() - 30 * 24 * 60 * 60 * 1000));
            } else if (value === '60 days') {
                inactiveUsers = data.users.filter(
                    user => Date.parse(user.metadata.lastRefreshTime || user.metadata.lastSignInTime) < (Date.now() - 60 * 24 * 60 * 60 * 1000));
            } else {
                inactiveUsers = data.users.filter(
                    user => Date.parse(user.metadata.lastRefreshTime || user.metadata.lastSignInTime) < (Date.now() - 90 * 24 * 60 * 60 * 1000));
            }

            setUsers(inactiveUsers);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            alert(err)
        }
    }

    const handleChange = async (event) => {
        setFilter(event.target.value);

        try {
            if (event.target.value === 'All') {
                setUpdate(!update);
                return;
            }

            setLoading(true);

            setPageToken(null);

            await filterData(event.target.value);
        } catch (err) {
            setLoading(false);
            alert(err);
        }
    };

    return <div className={classes.container}>
        <table id='users-list-table' style={{ width: '100%' }}>
            <thead>
                <tr>
                    <td>
                        Avatar
                    </td>
                    <td>
                        User name
                    </td>
                    <td>
                        Email
                    </td>
                    <td>
                        Last Active
                    </td>
                </tr>
            </thead>
            {loading ?
                <tbody>
                    <tr>
                        <td colSpan={6}>
                            <div className={classes.noRequestFound}>
                                <CircularProgress color="secondary" />
                            </div>
                        </td>
                    </tr>
                </tbody>
                : users.length === 0 ?
                    <tbody>
                        <tr>
                            <td colSpan={6}>
                                No User Found.
                            </td>
                        </tr>
                    </tbody>
                    : <tbody>
                        {
                            users.map((item, i) =>
                                <tr key={i} >
                                    <td>
                                        <Avatar
                                            alt='logo'
                                            src={item.photoURL}
                                            sx={{
                                                width: 45,
                                                height: 45,
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Link to={`/user/${item.uid}`} className={classes.username}>
                                            {item?.displayName}
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to={'mailto:' + item.email}>{item.email}</Link>
                                    </td>
                                    <td data-sort={Date.parse(item.metadata?.lastSignInTime)}>
                                        {item.metadata?.lastSignInTime}
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
            }
        </table>
    </div>;
}

export default User